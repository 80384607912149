import React, { useEffect } from "react";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import './custom.css'
import './Spinner.css'
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import indoordata from './indoorArrayData.js'
import outdoordata from './outDoorArrayData.js'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function TableRecord({ idScreen, dataIdScreenSelect, dataShare, setLoader,
    loader,
    downloadPDF, buttonStatus, tableDesgin, fontSizeText, WHData, setDownload, openModel, setOpenModel, setDataUser, dataUser, download,custome }) {
    const [selectEnvData, setSelectEnvData] = React.useState(dataIdScreenSelect);
    const [dataShareGet, setDataShareGet] = React.useState(dataShare);
    const [width50, setWidth50] = React.useState("");
    const [width33, setWidth33] = React.useState("");
    const [iconName, setIconName] = React.useState("DownloadIcon");
    // if (dataIdScreenSelect == "INDOOR") {
    //     setSelectEnvData(indoordata)
    // } else {
    //     setSelectEnvData(outdoordata)
    // }
    useEffect(() => {
        setSelectEnvData(dataIdScreenSelect)
        setDataShareGet(dataShare)
        if (buttonStatus) {
            setWidth50("width50")
            setWidth33("width33")
        }
        console.log(dataShare)
    })
    const handleClose = () => {
        setOpenModel(false)
        setDownload(0)
    };
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const [error, setError] = useState({
        projectName: false,
        name: false,
        email: false,
        phone: false,
        countryName: false,
        // attachInfo: 0,
    })

    const checkFiledData = () => {
        if (dataUser.projectName == "" && dataUser.name == "" && dataUser.email == "" && dataUser.countryName == "" && dataUser.phone == "") {
            setError(
                {
                    ...error,
                    "projectName": true,
                    "name": true,
                    "email": true,
                    "countryName": true,
                    "phone": true,
                }
            )
        } else {
            if (dataUser.projectName == "") {
                setError(
                    {
                        ...error,
                        "projectName": true,
                    }
                )
            } else if (dataUser.name == "") {
                setError(
                    {
                        ...error,
                        "name": true,
                    }
                )
            } else if (dataUser.email == "") {
                setError(
                    {
                        ...error,
                        "email": true,
                    }
                )
            } else if (dataUser.countryName == "") {
                setError(
                    {
                        ...error,
                        "countryName": true,
                    }
                )
            } else if (dataUser.phone == "") {
                setError(
                    {
                        ...error,
                        "phone": true,
                    }
                )
            } else {
                setLoader(true)
                downloadPDF()
            }

        }
    }
    return (
        <>
            <div className="" >
                <div className="row" >
                    {buttonStatus ? <>

                    </> : <>
                        <div className="col-md-12">
                            <h6 className="text-center text-white">SCREEN CONFIGURATION</h6>
                        </div></>}
                    <div className="col-md-12">
                        <table class={`table ${tableDesgin}`} style={{ fontSize: fontSizeText }}>
                            <thead>
                                <tr>
                                    <th className={width50}>COLUMNS</th>
                                    <th className={width50}>ROWS</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={width50}>{dataShareGet.col}</td>
                                    <td className={width50}>{dataShareGet.row}</td>

                                </tr>
                                <tr>
                                    <th className={width50}>Total PANELS</th>
                                    <td className={width50}>{dataShareGet.col * dataShareGet.row}</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="col-md-12">
                        <table class={`table ${tableDesgin}`} style={{ fontSize: fontSizeText }}>
                            <thead>
                                <tr>
                                    <td colSpan={3}>
                                        OVERALL SCREEN DIMENSIONS
                                    </td>
                                </tr>
                                <tr>
                                    <th className={width33}>WIDTH</th>
                                    <th className={width33}>HEIGHT</th>
                                    <th className={width33}>TOTAL AREA</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={width33}>{selectEnvData.cabinetSizeWxhW * dataShareGet.col} mm</td>
                                    <td className={width33}>{selectEnvData.cabinetSizeWxhH * dataShareGet.row} mm</td>
                                    <td className={width33}>{parseFloat((((selectEnvData.cabinetSizeWxhW * dataShareGet.col) * (selectEnvData.cabinetSizeWxhH * dataShareGet.row)) / 1000000).toFixed(3))} sqm</td>

                                </tr>


                            </tbody>
                        </table>
                    </div>

                    <div className="col-md-12">
                        <table class={`table ${tableDesgin}`} style={{ fontSize: fontSizeText }}>
                            <thead>
                                <tr>
                                    <td colSpan={3}>
                                        OVERALL SCREEN RESOLUTION
                                    </td>
                                </tr>
                                <tr>
                                    <th className={width33}>WIDTH</th>
                                    <th className={width33}>HEIGHT</th>
                                    <th className={width33}>TOTAL AREA</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={width33}>{selectEnvData.cabinetResolutionPXW * dataShareGet.col} px</td>
                                    <td className={width33}>{selectEnvData.cabinetResolutionPXH * dataShareGet.row} px</td>
                                    <td className={width33}>{(selectEnvData.cabinetResolutionPXW * dataShareGet.col) * (selectEnvData.cabinetResolutionPXH * dataShareGet.row)} px</td>

                                </tr>


                            </tbody>
                        </table>
                    </div>
                    {WHData.addNewData == 0 ? <>
                        <div className="col-md-12">
                            <table class={`table ${tableDesgin}`} style={{ fontSize: fontSizeText }}>
                                <thead>
                                    <tr>
                                        <td colSpan={3}>
                                            POWER CONSUMPTION
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={width50}>MAX POWER</th>
                                        <td className={width50}>
                                            {parseFloat(((((selectEnvData.cabinetSizeWxhW * dataShareGet.col) * (selectEnvData.cabinetSizeWxhH * dataShareGet.row)) / 1000000) * selectEnvData.maxPowerConsumption) / 1000).toFixed(3)} kWh</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className={width50}>AVG POWER</th>
                                        <td className={width50}> {parseFloat(((((selectEnvData.cabinetSizeWxhW * dataShareGet.col) * (selectEnvData.cabinetSizeWxhH * dataShareGet.row)) / 1000000) * selectEnvData.avgPowerConsumption) / 1000).toFixed(3)} kWh</td>


                                    </tr>


                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-12">
                            <table class={`table ${tableDesgin}`} style={{ fontSize: fontSizeText }}>
                                <thead>
                                    <tr>
                                        <td colSpan={3}>
                                            OTHER DETAILS
                                        </td>
                                    </tr>

                                </thead>
                                <tbody>
                                    {/* <tr>
                                    <th >HEAT DISSIPATION (MAX)</th>
                                    <td >
                                        2,393.4BTU/hr</td>

                                </tr>
                                <tr>
                                    <th >HEAT DISSIPATION</th>
                                    <td >
                                        957.4 BTU/hr</td>

                                </tr> */}
                                    <tr>
                                        <th className={width50}>TOTAL SCREEN WEIGHT</th>
                                        <td className={width50}>
                                            {parseFloat((dataShareGet.col * dataShareGet.row) * selectEnvData.weightCabinetKG).toFixed(0)} kg
                                        </td>

                                    </tr>

                                    <tr>
                                        <th className={width50}>MIN VIEW DISTANCE</th>
                                        <td className={width50}>

                                            {selectEnvData.pixelPitch} m</td>

                                    </tr>
                                    <tr>
                                        <th className={width50}>OPTIMAL VIEW DISTANCE</th>
                                        <td className={width50}>
                                            {parseFloat(Math.round(selectEnvData.pixelPitch * 2)).toFixed(0)} ~ {parseFloat(Math.round(selectEnvData.pixelPitch * 3)).toFixed(0)}  m</td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </> : ""}

                    <div className="col-md-12">
                        <Stack direction="row" spacing={2}>
                            {buttonStatus ? "" : <>
                                <Button variant="outlined" className="text-white" style={{ borderColor: "white" }} startIcon={<DownloadIcon />} onClick={() => {
                                    setOpenModel(true)
                                    setDownload(1)
                                    setError(
                                        {
                                            ...error,
                                            "projectName": false,
                                            "name": false,
                                            "email": false,
                                            "countryName": false,
                                            "phone": false,
                                        }
                                    )
                                }}>
                                    Download
                                </Button>
                                <Button variant="contained" endIcon={<SendIcon />} onClick={() => {
                                    setOpenModel(true)
                                    setDownload(2)
                                    setError(
                                        {
                                            ...error,
                                            "projectName": false,
                                            "name": false,
                                            "email": false,
                                            "countryName": false,
                                            "phone": false,
                                        }
                                    )
                                }}>
                                    EMAIL
                                </Button></>}
                            <React.Fragment>

                                <BootstrapDialog
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={openModel}
                                >
                                    {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
  SELECT YOUR ENVIRONMENT
  </DialogTitle> */}
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                        <br />
                                        <br />
                                        <div className='container'>
                                            <div className="row mx-auto">
                                                <div className="col-md-12` mx-auto text-center">
                                                    <h3>Fill These Information</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField id="standard-basic" label="Project Name" variant="standard"
                                                        value={dataUser.projectName}
                                                        error={error.projectName}
                                                        onChange={(e) => {
                                                            // alert( e.target.value)
                                                            setDataUser({ ...dataUser, "projectName": e.target.value })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField id="standard-basic" label="Name" variant="standard"
                                                        error={error.name}
                                                        value={dataUser.name}
                                                        onChange={(e) => {
                                                            setDataUser({ ...dataUser, "name": e.target.value })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField id="standard-basic" error={error.email} type="email" label=" Email" variant="standard"
                                                        value={dataUser.email}
                                                        onChange={(e) => {
                                                            setDataUser({ ...dataUser, "email": e.target.value })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField id="standard-basic" label="Country" variant="standard"
                                                        error={error.countryName}
                                                        value={dataUser.countryName}
                                                        onChange={(e) => {
                                                            setDataUser({ ...dataUser, "countryName": e.target.value })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField id="standard-basic" label="Phone Number" variant="standard"
                                                        error={error.phone}
                                                        value={dataUser.phone}
                                                        onChange={(e) => {
                                                            setDataUser({ ...dataUser, "phone": e.target.value })
                                                        }} />
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Attach Information." />

                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row mx-auto">
                                                <div className="col-md-6 mx-auto">
                                                    <Button variant="outlined" onClick={() => {

                                                        checkFiledData()
                                                    }} startIcon={
                                                        loader ? <>
                                                            <div className="spinner"></div>
                                                        </> : <>
                                                            <DownloadIcon />
                                                        </>
                                                    } >
                                                        {download == 2 ? <>
                                                            {loader ? "Sending" : "Send"}
                                                        </> : <>
                                                            {loader ? "Generating" : "Download"}
                                                        </>}

                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </DialogContent>

                                </BootstrapDialog>
                            </React.Fragment>



                        </Stack>
                    </div>



                </div>
            </div>
        </>
    );
}

export default TableRecord;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        // padding: theme.spacing(2),
        // width:"400px"
    },
    '& .MuiDialogActions-root': {
        // padding: theme.spacing(1),
    },
}));