const outdoor = [
    {
        category: "OUTDOOR",
        model: "FAB-PT5.7",
        description: "Platinum Series",
        seriesComId: 0,
        seriesImage: "PT-960X960-e.PNG",
        pixelPitch: 5.71,
        ledType: "SMD2727",
        brightness: 10000,
        cabinetSizeWxh: "960x960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "168x168",
        cabinetResolutionPXW: 168,
        cabinetResolutionPXH: 168,
        moduleSize: "480x320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "84x56",
        moduleResolutoinW: 84,
        moduleResolutoinH: 56,
        moduleConfig: "2x3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 28,
        maxPowerConsumption: 534,
        avgPowerConsumption: 160,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Die-casting Aluminum module for fast heat dissipation. ",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT6.67",
        description: "Platinum Series",
        seriesComId: 0,
        seriesImage: "PT-960X960-e.PNG",
        pixelPitch: 6.67,
        ledType: "SMD2727",
        brightness: 10000,
        cabinetSizeWxh: "960x960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "144x144",
        cabinetResolutionPXW: 144,
        cabinetResolutionPXH: 144,
        moduleSize: "480x320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "72x48",
        moduleResolutoinW: 72,
        moduleResolutoinH: 48,
        moduleConfig: "2x3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 28,
        maxPowerConsumption: 534,
        avgPowerConsumption: 160,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Die-casting Aluminum module for fast heat dissipation. ",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT8",
        description: "Platinum Series",
        seriesComId: 0,
        seriesImage: "PT-960X960-e.PNG",
        pixelPitch: 8,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "960x960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "120x120",
        cabinetResolutionPXW: 120,
        cabinetResolutionPXH: 120,
        moduleSize: "480x320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "60x40",
        moduleResolutoinW: 60,
        moduleResolutoinH: 40,
        moduleConfig: "2x3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 28,
        maxPowerConsumption: 506,
        avgPowerConsumption: 152,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Die-casting Aluminum module for fast heat dissipation. ",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT10",
        description: "Platinum Series",
        seriesComId: 0,
        seriesImage: "PT-960X960-e.PNG",
        pixelPitch: 10,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "960x960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "96x96",
        cabinetResolutionPXW: 96,
        cabinetResolutionPXH: 96,
        moduleSize: "480x320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "48x32",
        moduleResolutoinW: 48,
        moduleResolutoinH: 32,
        moduleConfig: "2x3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 28,
        maxPowerConsumption: 506,
        avgPowerConsumption: 152,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Die-casting Aluminum module for fast heat dissipation. ",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT320 5.71",
        description: "Platinum320 Series",
        seriesComId: 1,
        seriesImage: "PT320-e.png",
        pixelPitch: 5.71,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "1280X960",
        cabinetSizeWxhW: 1280,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "224x168",
        cabinetResolutionPXW: 224,
        cabinetResolutionPXH: 168,
        moduleSize: "320X320",
        moduleSizeW: 320,
        moduleSizeH: 320,
        moduleResolutoin: "56x56",
        moduleResolutoinW: 56,
        moduleResolutoinH: 56,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 30,
        maxPowerConsumption: 835,
        avgPowerConsumption: 252,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: " Die-casting Aluminum module for fast heat dissipation.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving.",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT320 6.67",
        description: "Platinum320 Series",
        seriesComId: 1,
        seriesImage: "PT320-e.png",
        pixelPitch: 6.67,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "1280X960",
        cabinetSizeWxhW: 1280,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "192X144",
        cabinetResolutionPXW: 192,
        cabinetResolutionPXH: 144,
        moduleSize: "320X320",
        moduleSizeW: 320,
        moduleSizeH: 320,
        moduleResolutoin: "48X48",
        moduleResolutoinW: 48,
        moduleResolutoinH: 48,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 30,
        maxPowerConsumption: 835,
        avgPowerConsumption: 252,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: " Die-casting Aluminum module for fast heat dissipation.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving.",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT320 8",
        description: "Platinum320 Series",
        seriesComId: 1,
        seriesImage: "PT320-e.png",
        pixelPitch: 8,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "1280X960",
        cabinetSizeWxhW: 1280,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "160X120",
        cabinetResolutionPXW: 160,
        cabinetResolutionPXH: 120,
        moduleSize: "320X320",
        moduleSizeW: 320,
        moduleSizeH: 320,
        moduleResolutoin: "40X40",
        moduleResolutoinW: 40,
        moduleResolutoin: 40,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 30,
        maxPowerConsumption: 835,
        avgPowerConsumption: 252,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: " Die-casting Aluminum module for fast heat dissipation.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving.",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PT320 10",
        description: "Platinum320 Series",
        seriesComId: 1,
        seriesImage: "PT320-e.png",
        pixelPitch: 10,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "1280X960",
        cabinetSizeWxhW: 1280,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "128X96",
        cabinetResolutionPXW: 128,
        cabinetResolutionPXH: 96,
        moduleSize: "320X320",
        moduleSizeW: 320,
        moduleSizeH: 320,
        moduleResolutoin: "32X32",
        moduleResolutoinW: 32,
        moduleResolutoinH: 32,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 30,
        maxPowerConsumption: 835,
        avgPowerConsumption: 252,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 20/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: " Die-casting Aluminum module for fast heat dissipation.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Common cathode technology for energy saving.",
            point4: "High brightness option for 10,000nits.",
            point5: "Totally front and rear service.",
            point6: "Stable and reliable performance.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTP6.67 ",
        description: "Platinum PRO Series",
        seriesComId: 2,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 6.67,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "800X900",
        cabinetSizeWxhW: 800,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "120X135",
        cabinetResolutionPXW: 120,
        cabinetResolutionPXH: 135,
        moduleSize: "400X300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "60X45",
        moduleResolutoinW: 60,
        moduleResolutoinH: 45,
        moduleConfig: "2X3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 21,
        maxPowerConsumption: 418,
        avgPowerConsumption: 125,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "5 years",
        seriesDescription: "",
        featuresId: {
            point1: " 400x300mm Aluminum module design for 16:9 Golden Ratio.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Support Power and signal back up.",
            point4: "Common cathode technology for energy-saving.",
            point5: "High brightness option to 10,000nits.",
            point6: "High quality components for 3 years to 5 years warranty",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTP8",
        description: "Platinum PRO Series",
        seriesComId: 2,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 8.33,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "800X900",
        cabinetSizeWxhW: 800,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "96X108",
        cabinetResolutionPXW: 96,
        cabinetResolutionPXH: 108,
        moduleSize: "400X300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "48X36",
        moduleResolutoinW: 48,
        moduleResolutoinH: 36,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 21,
        maxPowerConsumption: 396,
        avgPowerConsumption: 119,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "5 years",
        seriesDescription: "",
        featuresId: {
            point1: " 400x300mm Aluminum module design for 16:9 Golden Ratio.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Support Power and signal back up.",
            point4: "Common cathode technology for energy-saving.",
            point5: "High brightness option to 10,000nits.",
            point6: "High quality components for 3 years to 5 years warranty",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTP10",
        description: "Platinum PRO Series",
        seriesComId: 2,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 10,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "800X900",
        cabinetSizeWxhW: 800,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "80X90",
        cabinetResolutionPXW: 80,
        cabinetResolutionPXH: 90,
        moduleSize: "400X300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "40X30",
        moduleResolutoinW: 40,
        moduleResolutoinH: 30,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 21,
        maxPowerConsumption: 396,
        avgPowerConsumption: 119,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "5 years",
        seriesDescription: "",
        featuresId: {
            point1: " 400x300mm Aluminum module design for 16:9 Golden Ratio.",
            point2: "IP66 waterproof and 5VB fireproof.",
            point3: "Support Power and signal back up.",
            point4: "Common cathode technology for energy-saving.",
            point5: "High brightness option to 10,000nits.",
            point6: "High quality components for 3 years to 5 years warranty",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTS2.9",
        description: "Platinum S series",
        seriesComId: 3,
        seriesImage: "PTS_500x1000_e.png",
        pixelPitch: 2.9,
        ledType: "SMD1515",
        brightness: 5000,
        cabinetSizeWxh: "500X500",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "168X168",
        cabinetResolutionPXW: 168,
        cabinetResolutionPXH: 168,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "84X84",
        moduleResolutoinW: 84,
        moduleResolutoinH: 84,
        moduleConfig: "2X2",
        moduleConfigW: 2,
        moduleConfigH: 2,
        weightCabinetKG: 9.5,
        maxPowerConsumption: 167,
        avgPowerConsumption: 50,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Fine pixel pitch for outdoor fixed installation.",
            point2: "Die-casting Aluminum module for fast heat dissipation. ",
            point3: "IP66 waterproof and 5VB fireproof.",
            point4: "Easy service from front and rear side.",
            point5: "High brightness option to 7,000nits.",
            point6: "Stable performance for outdoor environment.",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTS3.91",
        description: "Platinum S series",
        seriesComId: 3,
        seriesImage: "PTS_500x1000_e.png",
        pixelPitch: 3.91,
        ledType: "SMD1921",
        brightness: 7000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "128X256",
        cabinetResolutionPXW: 128,
        cabinetResolutionPXH: 256,
        moduleSize: "500X250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "128X64",
        moduleResolutoinW: 128,
        moduleResolutoinH: 64,
        moduleConfig: "1X4",
        moduleConfigW: 1,
        moduleConfigH: 4,
        weightCabinetKG: 18,
        maxPowerConsumption: 285,
        avgPowerConsumption: 85,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Fine pixel pitch for outdoor fixed installation.",
            point2: "Die-casting Aluminum module for fast heat dissipation. ",
            point3: "IP66 waterproof and 5VB fireproof.",
            point4: "Easy service from front and rear side.",
            point5: "High brightness option to 7,000nits.",
            point6: "Stable performance for outdoor environment.",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTS4.81",
        description: "Platinum S series",
        seriesComId: 3,
        seriesImage: "PTS_500x1000_e.png",
        pixelPitch: 4.81,
        ledType: "SMD1921",
        brightness: 7000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "104X208",
        cabinetResolutionPXW: 104,
        cabinetResolutionPXH: 208,
        moduleSize: "500X250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "104X52",
        moduleResolutoinW: 104,
        moduleResolutoinH: 52,
        moduleConfig: "1X4",
        moduleConfigW: 1,
        moduleConfigH: 4,
        weightCabinetKG: 18,
        maxPowerConsumption: 270,
        avgPowerConsumption: 81,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Fine pixel pitch for outdoor fixed installation.",
            point2: "Die-casting Aluminum module for fast heat dissipation. ",
            point3: "IP66 waterproof and 5VB fireproof.",
            point4: "Easy service from front and rear side.",
            point5: "High brightness option to 7,000nits.",
            point6: "Stable performance for outdoor environment.",
        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-DM1.95",
        description: "Diamond Series",
        seriesComId: 4,
        seriesImage: "Diamonds-series-500x500-e.png",
        pixelPitch: 1.95,
        ledType: "SMD121",
        brightness: 4000,
        cabinetSizeWxh: "500X500",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "256X256",
        cabinetResolutionPXW: 256,
        cabinetResolutionPXH: 256,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "128X128",
        moduleResolutoinW: 128,
        moduleResolutoinH: 128,
        moduleConfig: "2X2",
        moduleConfigW: 2,
        moduleConfigH: 2,
        weightCabinetKG: 9,
        maxPowerConsumption: 140,
        avgPowerConsumption: 42,
        refreshmentHz: 3840,
        contrastRatio: "12000:1",
        viewingAngleHv: "160° , 160/160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "10000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Special design for outdoor high resolution screen.",
            point2: "Die-casting Aluminum module and cabinet for high precision.",
            point3: "Fully outdoor installation with IP65 and 5VB fireproof",
            point4: "Totally front service and rear service.",
            point5: "High brightness to 5,300nits.",
            point6: "Ideal solution for outdoor high-end applications.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-DM2.97",
        description: "Diamond Series",
        seriesComId: 4,
        seriesImage: "Diamonds-series-500x500-e.png",
        pixelPitch: 2.97,
        ledType: "SMD1515",
        brightness: 5300,
        cabinetSizeWxh: "500X500",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "168X168",
        cabinetResolutionPXW: 168,
        cabinetResolutionPXH: 168,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "84X84",
        moduleResolutoinW: 84,
        moduleResolutoinH: 84,
        moduleConfig: "2X2",
        moduleConfigW: 2,
        moduleConfigH: 2,
        weightCabinetKG: 8.5,
        maxPowerConsumption: 140,
        avgPowerConsumption: 42,
        refreshmentHz: 3840,
        contrastRatio: "14000:1",
        viewingAngleHv: "160° , 160/160°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "10000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Special design for outdoor high resolution screen.",
            point2: "Die-casting Aluminum module and cabinet for high precision.",
            point3: "Fully outdoor installation with IP65 and 5VB fireproof",
            point4: "Totally front service and rear service.",
            point5: "High brightness to 5,300nits.",
            point6: "Ideal solution for outdoor high-end applications.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PSP6.67",
        description: "Platinum Sports 960",
        seriesComId: 5,
        seriesImage: "PT Sport Series960×960_e.png",
        pixelPitch: 6.67,
        ledType: "SMD2727",
        brightness: 10000,
        cabinetSizeWxh: "960X960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "144X144",
        cabinetResolutionPXW: 144,
        cabinetResolutionPXH: 144,
        moduleSize: "480X320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "72X48",
        moduleResolutoinW: 72,
        moduleResolutoinH: 48,
        moduleConfig: "2X3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 31,
        maxPowerConsumption: 506,
        avgPowerConsumption: 184,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Platinum 960x960mm cabinets with Aluminum modules. ",
            point2: "Top rubber cover, soft mask for protection",
            point3: "Rotatable base, easy for installation. ",
            point4: "Side lock system. ",
            point5: "High refreshment for live broadcast.",
            point6: "Cable-free back side, total front & rear service. ",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PSP8",
        description: "Platinum Sports 960",
        seriesComId: 5,
        seriesImage: "PT Sport Series960×960_e.png",
        pixelPitch: 8,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "960X960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "120X120",
        cabinetResolutionPXW: 120,
        cabinetResolutionPXH: 120,
        moduleSize: "480X320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "60X40",
        moduleResolutoinW: 60,
        moduleResolutoin: 40,
        moduleConfig: "2X3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 31,
        maxPowerConsumption: 506,
        avgPowerConsumption: 184,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Platinum 960x960mm cabinets with Aluminum modules. ",
            point2: "Top rubber cover, soft mask for protection",
            point3: "Rotatable base, easy for installation. ",
            point4: "Side lock system. ",
            point5: "High refreshment for live broadcast.",
            point6: "Cable-free back side, total front & rear service. ",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PSP10",
        description: "Platinum Sports 960",
        seriesComId: 5,
        seriesImage: "PT Sport Series960×960_e.png",
        pixelPitch: 10,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "960X960",
        cabinetSizeWxhW: 960,
        cabinetSizeWxhH: 960,
        cabinetResolutionPX: "96X96",
        cabinetResolutionPXW: 96,
        cabinetResolutionPXH: 96,
        moduleSize: "480X320",
        moduleSizeW: 480,
        moduleSizeH: 320,
        moduleResolutoin: "48X32",
        moduleResolutoinW: 48,
        moduleResolutoinH: 32,
        moduleConfig: "2X3",
        moduleConfigW: 2,
        moduleConfigH: 3,
        weightCabinetKG: 31,
        maxPowerConsumption: 506,
        avgPowerConsumption: 184,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Platinum 960x960mm cabinets with Aluminum modules. ",
            point2: "Top rubber cover, soft mask for protection",
            point3: "Rotatable base, easy for installation. ",
            point4: "Side lock system. ",
            point5: "High refreshment for live broadcast.",
            point6: "Cable-free back side, total front & rear service. ",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PRP6.67",
        description: "Platinum Pro sports 1600",
        seriesComId: 6,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 6.67,
        ledType: "SMD2727",
        brightness: 9500,
        cabinetSizeWxh: "1600X900",
        cabinetSizeWxhW: 1600,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "240x135",
        cabinetResolutionPXW: 240,
        cabinetResolutionPXH: 135,
        moduleSize: "400x300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "60x45",
        moduleResolutoinW: 60,
        moduleResolutoinH: 45,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 64,
        maxPowerConsumption: 907,
        avgPowerConsumption: 360,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "170° , 40/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "UEFA standard design",
            point2: "1,600x900mm cabinet size. ",
            point3: "Players’ protective design.",
            point4: "Emergency access design.",
            point5: "Rotatable base. ",
            point6: "Dolly design for storage of cabinets.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PRP8.3",
        description: "Platinum Pro sports 1600",
        seriesComId: 8.3,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 6.67,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "1600X900",
        cabinetSizeWxhW: 1600,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "192x108",
        cabinetResolutionPXW: 192,
        cabinetResolutionPXH: 108,
        moduleSize: "400x300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "48x36",
        moduleResolutoinW: 48,
        moduleResolutoinH: 36,
        moduleConfig: "4X3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 64,
        maxPowerConsumption: 792,
        avgPowerConsumption: 288,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "170° , 40/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "UEFA standard design",
            point2: "1,600x900mm cabinet size. ",
            point3: "Players’ protective design.",
            point4: "Emergency access design.",
            point5: "Rotatable base. ",
            point6: "Dolly design for storage of cabinets.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PRP10",
        description: "Platinum Pro sports 1600",
        seriesComId: 6,
        seriesImage: "PTpor_1200x900_e.png",
        pixelPitch: 10,
        ledType: "SMD3535",
        brightness: 10000,
        cabinetSizeWxh: "1600X900",
        cabinetSizeWxhW: 1600,
        cabinetSizeWxhH: 900,
        cabinetResolutionPX: "160x90",
        cabinetResolutionPXW: 160,
        cabinetResolutionPXH: 90,
        moduleSize: "400x300",
        moduleSizeW: 400,
        moduleSizeH: 300,
        moduleResolutoin: "40x30",
        moduleResolutoinW: 40,
        moduleResolutoinH: 30,
        moduleConfig: "4x3",
        moduleConfigW: 4,
        moduleConfigH: 3,
        weightCabinetKG: 64,
        maxPowerConsumption: 792,
        avgPowerConsumption: 288,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "170° , 40/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+60",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "UEFA standard design",
            point2: "1,600x900mm cabinet size. ",
            point3: "Players’ protective design.",
            point4: "Emergency access design.",
            point5: "Rotatable base. ",
            point6: "Dolly design for storage of cabinets.",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTF3.9",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 3.9,
        ledType: "SMD1921",
        brightness: 7000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "256x256",
        cabinetResolutionPXW: 256,
        cabinetResolutionPXH: 256,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "128x64",
        moduleResolutoinW: 128,
        moduleResolutoinH: 64,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 580,
        avgPowerConsumption: 174,
        refreshmentHz: 3840,
        contrastRatio: "5000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    },
    // add new 7
    {
        category: "OUTDOOR",
        model: "FAB-PTF4.8",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 4.81,
        ledType: "SMD1921",
        brightness: 7000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "208x208",
        cabinetResolutionPXW: 208,
        cabinetResolutionPXH: 208,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "104x52",
        moduleResolutoinW: 104,
        moduleResolutoinH: 52,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 540,
        avgPowerConsumption: 162,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 30/60°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTF5.9",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 5.9,
        ledType: "SMD1921",
        brightness: 7000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "168x168",
        cabinetResolutionPXW: 168,
        cabinetResolutionPXH: 168,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "84x42",
        moduleResolutoinW: 84,
        moduleResolutoinH: 42,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 540,
        avgPowerConsumption: 162,
        refreshmentHz: 3840,
        contrastRatio: "10000:1",
        viewingAngleHv: "160° , 30/50°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTF6.2",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 6.2,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "160X160",
        cabinetResolutionPXW: 160,
        cabinetResolutionPXH: 160,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "80X40",
        moduleResolutoinW: 80,
        moduleResolutoinH: 40,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 550,
        avgPowerConsumption: 165,
        refreshmentHz: 3840,
        contrastRatio: "10000:1",
        viewingAngleHv: "160° , 30/50°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTF8.3",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 8.3,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "120X120",
        cabinetResolutionPXW: 120,
        cabinetResolutionPXH: 120,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "60X30",
        moduleResolutoinW: 60,
        moduleResolutoinH: 30,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 550,
        avgPowerConsumption: 165,
        refreshmentHz: 3840,
        contrastRatio: "10000:1",
        viewingAngleHv: "160° , 30/50°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    },
    {
        category: "OUTDOOR",
        model: "FAB-PTF10.4",
        description: "PTF Series",
        seriesComId: 6,
        seriesImage: "",
        pixelPitch: 10.4,
        ledType: "SMD2727",
        brightness: 9000,
        cabinetSizeWxh: "1000X1000",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "96X96",
        cabinetResolutionPXW: 96,
        cabinetResolutionPXH: 96,
        moduleSize: "500x250",
        moduleSizeW: 500,
        moduleSizeH: 250,
        moduleResolutoin: "48X24",
        moduleResolutoinW: 48,
        moduleResolutoinH: 24,
        moduleConfig: "2x4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.5,
        maxPowerConsumption: 500,
        avgPowerConsumption: 165,
        refreshmentHz: 3840,
        contrastRatio: "10000:1",
        viewingAngleHv: "160° , 30560°",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-40~+55",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "",
            point2: "",
            point3: "",
            point4: "",
            point5: "",
            point6: "",

        }

    }




]
export default outdoor 