import React from "react";
import { useState } from "react";
import Sidebar from "./Sidebar";
import Indoorscreen from "./Indoorscreen";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TableRecord from "./TableRecord";
import background from "./../images/preview.gif"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './../Component/custom.css'
import emailjs from '@emailjs/browser';
import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, Text, PDFDownloadLink, Image } from '@react-pdf/renderer';
import Tseries from "./Tseries";
import TmaxCob from "./TmaxCob";
import PtSeries from "./PtSeries";
import Magicpad from "./Magicpad";
import InpadMax from "./InpadMax";
import axios from 'axios';
// import TM from "./TM";
import TMax from "./TMax";
import Demo from "./Demo";
function Homepage() {
    const [visible, setVisible] = useState(true);
    const [hight, updatehighte] = useState(600);
    const [openModel, setOpenModel] = React.useState(false);
    const [widthCheck, setWidthCheck] = React.useState(true);
    const [heightCheck, setHeightCheck] = React.useState(true);
    const [heightCheckMain, setHeightCheckMain] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [openAlert, setOpenAlert] = React.useState(false);
    const [colRom, setColRom] = React.useState({
        row: 2,
        col: 2
    })
    const [WHData, setWHData] = useState({
        width: 5,
        height: 3,
        groundHeight: 1,
        imageIndoor: "",
        imagePossition: "-0px",
        scale: 1,
        heightPointScale: 0,
        typeOutDoor: 0,
        addNewData: 0,
        boxWidth: 0,
        boxHeight: 0
    });
    const [dataUser, setDataUser] = useState({
        projectName: "",
        name: "",
        email: "",
        phone: "",
        countryName: "",
        attachInfo: 0,
    })
    const [selectEnvDataH, setSelectEnvDataH] = React.useState();
    const [selectEnv, setSelectEnv] = React.useState(0);
    const [selectEnvWithComId, setSelectEnvWithComId] = React.useState([]);
    const [idOfSelectScreen, setIdOfSelectScreen] = useState(-1)
    const [download, setDownload] = useState(0)
    const [imageBgSelect, setImageBgSelect] = useState(background)
    const [dataIdScreenSelect, setDataIdScreenSelect] = useState()
    const [dataShare, setDataShare] = useState({ row: 2, col: 2 })

    //type of ENVIRONMENT
    const [typeEnvironment, setTypeEnviroment] = useState(1)

    const [loader, setLoader] = useState(false);
    const downloadPDF = () => {

        const pages = [
            document.getElementById('pdf-content-1'),
            document.getElementById('pdf-content-2'),
            document.getElementById('pdf-content-3'),
            document.getElementById('pdf-content-4'),
            document.getElementById('pdf-content-5'),
        ].filter(page => page); // Filter out null elements

        if (pages.length === 0) {
            console.error('No valid elements found for PDF content.');
            setLoader(false)
            return;
        }

        const pdf = new jsPDF('p', 'pt', 'a4'); // 'pt' is points, 1 inch = 72 points
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        let yOffset = 0;



        setLoader(true);
        // Helper function to capture each page and add it to the PDF
        const addPageToPDF = (index) => {
            if (index >= pages.length) {

                if (download == 1) {


                    pdf.save(`${dataUser.projectName} - Huetech Configuration.pdf`);
                    // const blob = pdf.output('blob');
                    // const pdfData = pdf.output('datauristring');
                    // Sending the PDF data to the server
                    setMessage("Download File!")
                    setOpenAlert(true)
                    setDownload(0)
                    setLoader(false);
                    // setOpenModel(false)
                    return; // All pages added, save PDF and exit
                } else {
                    const pdfData = pdf.output('datauristring');

                    axios.post('https://backendconfigurator.huetech.com.kw/api/send-email', { pdfData, dataUser })
                        .then(response => {
                            setMessage("Email Send!")
                            setOpenAlert(true)
                            setLoader(false);
                            console.log(response.data);
                        })
                        .catch(error => {
                            setMessage("Email Technically issue!")
                            setOpenAlert(true)
                            setLoader(false);
                            console.error('Error sending email:', error);
                        });
                    // alert("sending")

                    return; // All pages added, save PDF and exit
                }

            }

            const currentPage = pages[index];
            html2canvas(currentPage, { backgroundColor: "black" }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                const imgHeight = (canvas.height * pageWidth) / canvas.width;

                // Add page to PDF
                if (index !== 0) {
                    pdf.addPage();
                }
                pdf.addImage(imgData, 'JPEG', 0, yOffset, pageWidth, imgHeight);


                // Capture and add next page
                addPageToPDF(index + 1);
                // yOffset -= pageHeight;
            });
        };

        // Start capturing and adding pages
        addPageToPDF(0);

    }


    const handleCloseAlert = () => {

        setOpenAlert(false);
    };
    return (
        <>
            <div className="container-fluid bg-dark" style={{ height: "100vh" }}>
                <div className="row">
                    <div className="col-md-12">

                        <nav class="navbar bg-dark bsb-navbar bsb-navbar-caret">
                            <div class="container position-relative justify-content-center">
                                <a class="navbar-brand" href="https://www.huetech.com.kw/">
                                    <img src={require('./../images/Huetech-dark-logo.png')} alt="BootstrapBrain Logo" width="200" height="31" />
                                </a>


                            </div>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2" >
                        <Sidebar hight={hight} updateHighte={updatehighte}
                            setDataIdScreenSelect={setDataIdScreenSelect}
                            dataIdScreenSelect={dataIdScreenSelect}
                            idScreen={idOfSelectScreen}
                            updateIdScreen={setIdOfSelectScreen}
                            setDataShare={setDataShare}
                            setImageBgSelect={setImageBgSelect}
                            setWHData={setWHData}
                            WHData={WHData}
                            setSelectEnv={setSelectEnv}
                            selectEnv={selectEnv}
                            setSelectEnvDataH={setSelectEnvDataH}
                            widthCheck={widthCheck}
                            setColRom={setColRom}
                            colRom={colRom}
                            setWidthCheck={setWidthCheck}
                            setHeightCheck={setHeightCheck}
                            heightCheck={heightCheck}
                            setHeightCheckMain={setHeightCheckMain}
                            heightCheckMain={heightCheckMain}

                        />

                    </div>

                    <div className="col-md-8" style={{height:"90vh"}}>
                        <Indoorscreen hight={hight} dataShare={dataShare}
                            dataIdScreenSelect={dataIdScreenSelect}
                            idScreen={idOfSelectScreen}
                            imageBgSelect={imageBgSelect}
                            WHData={WHData}
                            selectEnv={selectEnv}
                            setDataShare={setDataShare}
                            setSelectEnvWithComId={setSelectEnvWithComId}
                            setColRom={setColRom}
                            colRom={colRom}
                            setWidthCheck={setWidthCheck}
                            widthCheck={widthCheck}
                            setHeightCheck={setHeightCheck}
                            setHeightCheckMain={setHeightCheckMain}
                            heightCheck={heightCheck}
                            setWHData={setWHData}
                            paddingBox={"0px"}
                        />
                    </div>
                    <div className="col-md-2 ">
                        {idOfSelectScreen != -1 ? <TableRecord idScreen={idOfSelectScreen} dataIdScreenSelect={dataIdScreenSelect}
                            setDataUser={setDataUser}
                            dataShare={dataShare}
                            dataUser={dataUser}
                            setLoader={setLoader}
                            loader={loader}
                            WHData={WHData}
                            downloadPDF={downloadPDF}
                            buttonStatus={false}
                            tableDesgin={"table-striped table-dark"}
                            fontSizeText={"10px"}
                            setDownload={setDownload}
                            download={download}
                            openModel={openModel}
                            setOpenModel={setOpenModel}
                        /> : ""}
                    </div>
                </div>



            </div >
            {/* <Demo /> */}
            {
                download != 0 ? <>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="container" id="pdf-content-1">
                        {/* <PDFViewer style={styles.viewer}> */}
                        {/* Start of the document*/}

                        <div className="card-header ">
                            <img src={require('./../images/Huetech-dark-logo.png')} alt="BootstrapBrain Logo" width="250" height="40" style={{ marginTop: "10px" }} />
                        </div>

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="row mx-auto">

                            <div class=" p-5 bg-dark text-white rounded">
                                <h1 style={{ float: "left" }}>Project Name : {dataUser.projectName}</h1>
                                {/* <h3 style={{ float: "left" }}>{dataUser.projectName}</h3> */}
                            </div>
                            {/* <div className="col-md-12 mx-auto " style={{ background: "black" }}> */}
                            <div className="card" style={{ background: "black", height: "2000px" }}>

                                <br></br>
                                <br></br>
                                <br></br>

                                <br></br> <br></br>
                                <br></br> <br></br>
                                <br></br>
                                <div className="card-body" style={{ fontSize: "25px", background: "black" }}>

                                    <Indoorscreen

                                        hight={hight} dataShare={dataShare}
                                        dataIdScreenSelect={dataIdScreenSelect}
                                        idScreen={idOfSelectScreen}
                                        imageBgSelect={imageBgSelect}
                                        WHData={WHData}
                                        selectEnv={selectEnv}
                                        style={{ background: "black" }}
                                        paddingBox={"0px"}
                                    />


                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        {/* </PDFViewer> */}
                    </div>

                    <br></br>
                    <div className="container" id="pdf-content-2">
                        {/* <PDFViewer style={styles.viewer}> */}
                        {/* Start of the document*/}


                        <div className="row mx-auto" >
                            <div className="col-md-12 mx-auto " style={{ background: "black" }}>
                                <div className="card " style={{ background: "black", marginBottom: "495px" }}>
                                    <div className="card-header">
                                        <img src={require('./../images/Huetech-dark-logo.png')} alt="BootstrapBrain Logo" width="250" height="40" style={{ marginTop: "10px" }} />
                                    </div>

                                    <br></br>
                                    <div className="card-body " style={{ fontSize: "30px", height: "2000px" }}>
                                        <div className="container">
                                            {idOfSelectScreen != -1 ? <TableRecord idScreen={idOfSelectScreen} dataIdScreenSelect={dataIdScreenSelect}
                                                setDataUser={setDataUser}
                                                dataShare={dataShare}
                                                dataUser={dataUser}
                                                setLoader={setLoader}
                                                loader={loader}
                                                downloadPDF={downloadPDF}
                                                buttonStatus={true}
                                                WHData={WHData}
                                                tableDesgin={"tablePdftop table table-bordered table-dark "}
                                                fontSize={"15px"}
                                                custome={false}
                                            /> : ""}

                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                        {/* </PDFViewer> */}

                    </div>
                    <br></br>

                    {
                        WHData.addNewData == 0 ? <>

                            <div className="container" id="pdf-content-3">
                                {/* <PDFViewer style={styles.viewer}> */}
                                {/* Start of the document*/}


                                <div className="row mx-auto">

                                    <div className="col-md-12 mx-auto" style={{ background: "black" }}>
                                        <div className="card" style={{ background: "black", height: "2000px" }}>
                                            <div className="card-header">
                                                <img src={require('./../images/Huetech-dark-logo.png')} alt="BootstrapBrain Logo" width="250" height="40" style={{ marginTop: "10px" }} />
                                            </div>
                                            <div class="card-body">
                                                <div class="text-overlay ">
                                                    <h2 style={{ fontSize: "160px", color: "white" }}>{dataIdScreenSelect?.description}</h2>
                                                </div>
                                                <img className="" src={require('./../images/pdfImage.jpeg')} width={"100%"} height={"100%"} />

                                            </div>


                                        </div>


                                    </div>
                                </div>

                                {/* </PDFViewer> */}
                            </div>


                            <br></br>
                            <div className="container " id="pdf-content-4">
                                {/* <PDFViewer style={styles.viewer}> */}
                                {/* Start of the document*/}


                                <div className="row mx-auto">

                                    <div className="col-md-12 mx-auto" style={{ background: "black" }}>

                                        <div className="card " style={{ background: "black", height: "2000px" }}>
                                            <div className="card-header">
                                                <img src={require(`./../images/Huetech-dark-logo.png`)} alt="BootstrapBrain Logo" width="250" height="40" style={{ marginTop: "10px" }} />
                                            </div>

                                            <br></br>
                                            <div className="card-body " style={{ fontSize: "25px" }}>
                                                <div className="container">
                                                    <img src={require(`./../images/${dataIdScreenSelect?.seriesImage}`)} style={{ float: "right" }} width={"35%"} height={"32%"} />

                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="headingpdf" style={{ width: "520px", borderBottom: "2px solid white", fontSize: "45px" }}>
                                                        <h1 >OVERVIEW & APPLICATIONS</h1>
                                                    </div>

                                                    <div className="text-white" style={{ fontFamily: "Arial", fontSize: "33px" }}>

                                                        {selectEnv == 0 ? (
                                                            dataIdScreenSelect?.seriesComId == 0 ? (
                                                                <TMax />
                                                            ) : dataIdScreenSelect?.seriesComId == 1 ? (
                                                                <TmaxCob />
                                                            ) : dataIdScreenSelect?.seriesComId == 2 ? (
                                                                <InpadMax />
                                                            ) : dataIdScreenSelect?.seriesComId == 6 ? (
                                                                <Tseries />
                                                            ) : dataIdScreenSelect?.seriesComId == 7 ? (
                                                                <Magicpad />
                                                            )
                                                                : (
                                                                    <>Deescription not found</>
                                                                )
                                                        ) : (
                                                            <PtSeries />
                                                        )}

                                                        {/* {dataIdScreenSelect?.seriesComId == 6 ? <>
                                                            <Tseries />
                                                        </> : <>
                                                            <TmaxCob />
                                                        </>} */}

                                                    </div>


                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {/* </PDFViewer> */}
                            </div>

                            <br></br>
                            <div className="container " id="pdf-content-5">
                                {/* <PDFViewer style={styles.viewer}> */}
                                {/* Start of the document*/}


                                <div className="row mx-auto">
                                    <div className="col-md-12 mx-auto" style={{ background: "black", height: "2000px" }}>
                                        <div className="card" style={{ background: "black" }}>
                                            <div className="card-header">
                                                <img src={require('./../images/Huetech-dark-logo.png')} alt="BootstrapBrain Logo" width="250" height="40" style={{ marginTop: "10px" }} />
                                            </div>

                                            <br></br>
                                            <div className="card-body " style={{ fontSize: "25px", height: "1600px" }}>
                                                <div className="">
                                                    <table className="table tablePdf table-bordered table-dark" border={1}>
                                                        <tr>
                                                            <th>MODEL</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <th>
                                                                        {list.model}
                                                                    </th>
                                                                )
                                                            ))}
                                                        </tr>


                                                        <tr>
                                                            <th>Pixel pitch
                                                                (mm)</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.pixelPitch}
                                                                    </td>
                                                                )
                                                            ))}

                                                        </tr>
                                                        <tr>
                                                            <th>Module size
                                                                (mm)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.moduleSize}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Module resolution
                                                                (px)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.moduleResolutoin}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Cabinet size
                                                                (mm)</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.cabinetSizeWxh}
                                                                    </td>
                                                                )
                                                            ))}

                                                        </tr>
                                                        <tr>
                                                            <th>Cabinet resolution
                                                                (px)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.cabinetResolutionPX}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Weight/Cabinet(KG)</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.weightCabinetKG}
                                                                    </td>
                                                                )
                                                            ))}

                                                        </tr>
                                                        <tr>
                                                            <th>Refresh rate
                                                                (Hz)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.refreshmentHz}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Brightness
                                                                (nits)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.brightness}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        {/* <tr>
                                            <th>Brightness uniformity</th>

                                            {selectEnvDataH?.map(list => (
                                                // Condition to render only admin users
                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                    <td>
                                                        {list.pixelPitch}
                                                    </td>
                                                )
                                            ))}
                                        </tr> */}
                                                        <tr>
                                                            <th>Contrast</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.contrastRatio}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Viewing angle
                                                                (H/V)º</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.viewingAngleHv}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        {/* <tr>
                                            <th>Power consumption
                                                (W/m²)</th>
                                            {selectEnvDataH?.map(list => (
                                                // Condition to render only admin users
                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                    <td>
                                                        {list.pixelPitch}
                                                    </td>
                                                )
                                            ))}

                                        </tr> */}
                                                        {/* <tr>
                                            <th>Heat dissipation
                                                (BTU/hr/m²)</th>

                                            {selectEnvDataH?.map(list => (
                                                // Condition to render only admin users
                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                    <td>
                                                        {list.pixelPitch}
                                                    </td>
                                                )
                                            ))}
                                        </tr> */}
                                                        <tr>
                                                            <th>Working voltage</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.workingVoltage}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Operating temp</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.operatingTempC}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <th>Operating humidity</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.operatingHumidity}
                                                                    </td>
                                                                )
                                                            ))}

                                                        </tr>
                                                        <tr>
                                                            <th>LED lifetime
                                                                (hours)</th>

                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.ledLifetimeH}
                                                                    </td>
                                                                )
                                                            ))}
                                                        </tr>
                                                        {/* <tr>
                                            <th>Serviceability</th>
                                            {selectEnvDataH?.map(list => (
                                                // Condition to render only admin users
                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                    <td>
                                                        {list.pixelPitch}
                                                    </td>
                                                )
                                            ))}

                                        </tr> */}
                                                        <tr>
                                                            <th>Warranty</th>
                                                            {selectEnvDataH?.map(list => (
                                                                // Condition to render only admin users
                                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                                    <td>
                                                                        {list.warranty}
                                                                    </td>
                                                                )
                                                            ))}

                                                        </tr>
                                                        {/* <tr>
                                            <th>Ergonomics</th>
                                            {selectEnvDataH?.map(list => (
                                                // Condition to render only admin users
                                                dataIdScreenSelect?.seriesComId == list.seriesComId && (
                                                    <td>
                                                        {list.pixelPitch}
                                                    </td>
                                                )
                                            ))}

                                        </tr> */}
                                                    </table>

                                                </div>

                                            </div>

                                            <div class="card-footer jumbotron text-white container" >
                                                <h4>About Huetech</h4>
                                                <p style={{ textAlign: "justify", wordSpacing: "1px", fontSize: "25px" }}>

                                                    HUETECH is the leader in LED technology in the Middle East. We help clients from all industries to install impressive  and high performance LED screens that suits the environment perfectly.
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* </PDFViewer> */}
                            </div>
                        </> : ""
                    }


                </> : ""
            }

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert
                    onClose={handleCloseAlert}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%', background: "black" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Homepage;
const styleH1 = {
    fontSize: '15px',
    textAlign: 'center',
    color: 'red'
};

const invisibleStyle = {
    display: 'none',
};
